import React from 'react'
import { calender_icon, clock_icon, location_icon } from '../../assets/icons/icons'
import dayjs from 'dayjs'
import { ThemeButton } from '../components'

const Banner = ({ data, onChange }) => {
    return (
        <div className="relative bg-black/80 text-white rounded-2xl overflow-hidden shadow-lg my-5 group">
            <img
                src={data.banner}
                alt="Event Background"
                className="w-full h-80 object-cover opacity-60 group-hover:opacity-30 transition-opacity duration-300"
            />
            <div className="absolute inset-0 flex flex-col justify-between p-10 space-y-6">
                <div className="flex w-[252px] justify-center items-center space-x-4 border border-white py-3 rounded-3xl">
                    <span className="flex items-center space-x-2 text-sm">
                        <img src={calender_icon} alt="icon" width={17} height={17} />
                        <p className="font-semibold">
                            {dayjs(data.start_date).format("DD MMM - YYYY")}
                        </p>
                    </span>
                    <span className="flex items-center space-x-2 text-sm">
                        <img src={clock_icon} alt="icon" width={17} height={17} />
                        <p className="font-semibold">
                            {dayjs(data.start_date).format("hh:mm A")}
                        </p>
                    </span>
                </div>
                <div className="w-[500px]">
                    <h1 className="text-3xl font-bold mb-1 leading-[40px] line-clamp-3">
                        {data.name}
                    </h1>
                </div>
                <div className="flex w-[252px] space-x-4 py-3">
                    <span className="flex items-center space-x-2 text-sm">
                        <img src={location_icon} alt="icon" width={17} height={17} />
                        <p className="font-semibold">{data.location}</p>
                    </span>
                </div>
                <div className="absolute top-0 right-4 flex space-x-4">
                    {data.start_date === new Date() && (
                        <div className="flex justify-center items-center gap-2">
                            <span className="w-2 h-2 bg-red-600 rounded-full"></span>
                            <p className="font-semibold">Live</p>
                        </div>
                    )}
                </div>
                {/* Hover Edit Button */}
                <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    {/* <div className="bg-white text-black px-6 cursor-pointer py-2 rounded-md shadow-lg font-semibold">
                        <label htmlFor="upload" className="cursor-pointer">
                            <input
                                type="file"
                                onChange={onChange}
                                className="hidden"
                                name="upload"
                                id="upload"
                                multiple={false}
                            />
                            Update cover image
                        </label>
                    </div> */}

                    <ThemeButton
                        content={
                            <label htmlFor="upload" className="cursor-pointer">
                                <input
                                    type="file"
                                    onChange={onChange}
                                    className="hidden"
                                    name="upload"
                                    id="upload"
                                    multiple={false}
                                />
                                Update cover image
                            </label>
                        }
                    />

                </div>
            </div>
        </div>
    )
}

export default Banner
