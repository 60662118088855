const StatsCard = ({ icon, title, count, className }) => {
  return (
    <div
      className={`${className} bg-white shadow-sm p-7 rounded-3xl h-48 flex justify-center gap-5 items-center`}
    >
      <div className="w-[86px] h-[86px] rounded-full bg-[#FFF9D3] flex justify-center items-center">
        <img  src={icon} alt="icons" width={40} />
      </div>
      <div className="">
        <p className="text-base text-[#ACACAC]">{title}</p>
        <p className="text-3xl font-semibold">{count}</p>
      </div>
    </div>
  );
};

export default StatsCard;
