import React from 'react'
import { calender_icon, clock_icon, delete_icon, delete_icon_red, edit_icon, location_icon } from '../../assets/icons/icons'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from 'antd'

const EventBanner = ({ data, loader, showDeleteConfirm, onEdit }) => {
    const navigate = useNavigate()

    if (loader) return <Skeleton />
    if (!data || data.length === 0) return <Skeleton />;

    const handleNavigate = (id) => {
        navigate(`/event/${id}`)
        console.log("id==>", id)
    }
    console.log("Ongoing Events===>", data)
    return (

        data.map((data) => (
            <div onClick={() => handleNavigate(data._id)} class="relative bg-black text-white rounded-2xl overflow-hidden shadow-lg cursor-pointer my-5">
                <img src={data.banner} alt="Event Background" class="w-full h-80 object-cover opacity-50" />
                <div class="absolute inset-0 flex flex-col justify-between p-6 space-y-6">
                    <div class="flex w-[252px] justify-center items-center  space-x-4 border border-white py-3 rounded-3xl">
                        <span class="flex items-center space-x-2 text-sm">
                            <img src={calender_icon} alt='icon' width={17} height={17} />
                            <p className='font-semibold'>
                                {dayjs(data.start_date).format('DD MMM - YYYY')}
                            </p>

                        </span>
                        <span class="flex items-center space-x-2 text-sm">
                            <img src={clock_icon} alt='icon' width={17} height={17} />

                            <p className='font-semibold'>

                                {dayjs(data.start_date).format('hh:mm A')}

                            </p>
                        </span>
                    </div>
                    <div className='w-[500px]'>
                        <h1 class="text-3xl font-bold mb-1 leading-[40px] line-clamp-3">{data.description}</h1>
                    </div>
                    <div class="flex w-[252px] space-x-4 py-3">
                        <span class="flex items-center space-x-2 text-sm">
                            <img src={location_icon} alt='icon' width={17} height={17} />
                            <p className='font-semibold'>{data.location}</p>
                        </span>

                    </div>
                    <div class="absolute top-0 right-4 flex space-x-4">

                        {data.start_date === new Date() &&
                            <div className='flex justify-center items-center gap-2'>
                                <span className='w-2 h-2 bg-red-600 rounded-full'></span>
                                <p className='font-semibold'>Live</p>
                            </div>
                        }
                    </div>
                    <div class="absolute bottom-10 right-4 flex space-x-4">
                        <button onClick={(e) => {
                            e.stopPropagation();
                            onEdit(data._id)
                        }} class="bg-white text-white w-9 h-9 rounded-full flex justify-center items-center">
                            <img src={edit_icon} alt='icon' width={17} height={17} />
                        </button>
                        <button onClick={(e) => {
                            e.stopPropagation()
                            showDeleteConfirm(data._id)
                        }} class="bg-white w-9 h-9 rounded-full flex justify-center items-center">
                            <img src={delete_icon} alt='icon' width={17} height={17} />

                        </button>
                    </div>
                </div>
            </div>

        ))


    )
}

export default EventBanner
