import { useState, useEffect, useRef } from "react";
import { message, Spin, Tabs, Modal } from "antd";
import {
  add_icon,
  edit_icon,
  calender_icon,
  calender_outlined_icon,
  events_white_icon,
  right_arrow_icon,
  delete_icon,
  calendar_stack_icon,
  plus_white_icon
} from "../../assets/icons/icons";
import {
  Container,
  EventBanner,
  EventDrawer,
  ThemeButton,
  ThemeTable,
} from "../../components/components";
import { NavLink, useSearchParams, useNavigate } from "react-router-dom";
import {
  useAddEventsMutation,
  usePutEventsMutation,
  useLazyGetEventsQuery,
  useDeleteEventMutation,
} from "../../services/events";
import dayjs from "dayjs";
import { BASE_URL } from "../../constants/constants";
import { getValueFromStorage } from "../../helpers/helpers";

const Event = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState(
    searchParams.get("tab") || "1"
  );
  const [open, setOpen] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [editID, setEditID] = useState("");
  const [mode, setMode] = useState("create");
  const [ongoingEvents, setOngoingEvents] = useState([]);
  const [upcomingEvents, setupcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [getEvents, { isLoading }] = useLazyGetEventsQuery();
  const [addEvent, { isLoading: addingEvent }] = useAddEventsMutation();
  const [putEvents, { isLoading: updatingEvent }] = usePutEventsMutation();
  const [deleteEvent, { isLoading: deleteLoagding }] = useDeleteEventMutation();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);

  useEffect(() => {
    fetchEvents();
  }, [selectedKey]);

  const [formData, setFormData] = useState({
    name: "",
    banner: "",
    start_date: "",
    end_date: "",
    location: "",
    discussion: "",
    speakers: "",
    meetings: "",
    networking_hours: "",
    booths: "",
    description: "",
    event_hall: "",
  });
  const [image, setImage] = useState("");

  const fetchEvents = async () => {
    let ongoing = selectedKey === "1" || "";
    let upcoming = selectedKey === "2" || "";
    let past = selectedKey === "3" || "";
    const response = await getEvents({ ongoing, upcoming, past });
    console.log("res", response);
    selectedKey === "1"
      ? setOngoingEvents(response?.data?.events?.data)
      : selectedKey === "2"
        ? setupcomingEvents(response?.data?.events?.data)
        : selectedKey === "3" && setPastEvents(response?.data?.events?.data);
  };

  const onClose = () => {
    setOpen(false);
    setFormData({
      name: "",
      banner: "",
      start_date: "",
      end_date: "",
      location: "",
      discussion: "",
      speakers: "",
      meetings: "",
      networking_hours: "",
      booths: "",
      description: "",
      event_hall: "",
    });
    setImage("");
    setMode("create");
  };
  const formRef = useRef();
  console.log("Data======>", ongoingEvents)

  const handleEvent = async (FieldsData) => {
    console.log(FieldsData, "FieldsData");

    // Prepare data for sending
    const formatDate = (date) => {
      if (!date) return null;
      return new Date(date).toISOString();
    };

    const data = {
      name: FieldsData.eventName,
      banner: FieldsData.banner,
      start_date: formatDate(FieldsData.start_date),
      end_date: formatDate(FieldsData.end_date),
      location: FieldsData.location,
      discussion: FieldsData.discussion,
      speakers: FieldsData.speakers,
      meetings: FieldsData.meetings,
      networking_hours: FieldsData.networking_hours,
      booths: FieldsData.booths,
      description: FieldsData.description,
      event_hall: FieldsData.event_hall,
    };

    console.log("Formatted data", data);

    let response;
    try {
      if (mode === "create") {
        response = await addEvent(data);
        if (response?.data?.status === 200) {
          message.success("Event Created Successfully");

          onClose();
          setResetForm(true);
          fetchEvents();

          setTimeout(() => {
            setResetForm(false);
          }, 2000);
        } else {
          message.error(response?.data?.message || "Error creating event");
        }
      } else if (mode === "edit") {
        response = await putEvents({ id: editID, body: data });
        if (response?.data?.status === 200) {
          message.success("Event Updated Successfully");

          onClose();
          setResetForm(true);

          fetchEvents();

          setTimeout(() => {
            setResetForm(false);
          }, 2000);
        } else {
          message.error(response?.data?.message || "Error updating event");
        }
      }
    } catch (error) {
      console.error("Request failed", error);
      message.error("An unexpected error occurred");
    }
  };

  const onEdit = (data) => {
    setEditID(data._id);
    setFormData({
      name: data?.eventName,
      banner: data?.banner,
      start_date: dayjs(data?.start_date),
      end_date: dayjs(data?.end_date),
      location: data?.location,
      discussion: data?.discussion,
      speakers: data?.speakers,
      meetings: data?.meetings,
      networking_hours: data?.networking_hours,
      booths: data?.booths,
      description: data?.description,
      event_hall: data.event_hall,
    });
    setImage(data?.banner);
    setMode("edit");
    setOpen(true);
  };

  const showDeleteConfirm = (event) => {
    setEventToDelete(event);
    setDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    if (eventToDelete) {
      try {
        const response = await deleteEvent(eventToDelete._id);
        if (response?.data?.status === 200) {
          message.success("Event Deleted Successfully");
          fetchEvents();
        } else {
          message.error(response?.data?.message || "Error deleting event");
        }
      } catch (error) {
        console.error("Delete request failed", error);
        message.error("An unexpected error occurred while deleting the event");
      }
    }
    setDeleteModalVisible(false);
    setEventToDelete(null);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setEventToDelete(null);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "10%",
      render: (item, record, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "Banner",
      dataIndex: "banner",
      key: "banner",
      widthL: "20%",
      render: (text) => (
        <div className="flex justify-center">
          <img src={text} alt="" width={45} />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p className="text-center font-medium">{text}</p>,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (text) => (
        <NavLink to={`/event/${text}`} className="flex justify-center">
          <p className="text-center font-medium">
            {new Date(text).toDateString()}
          </p>
        </NavLink>
      ),
    },
    {
      title: "In Action",
      dataIndex: "_id",
      key: "_id",
      width: "15%",
      render: (text, data) => (
        <span className="flex justify-center items-center z-10">
          <span
            className="mx-2 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onEdit(data);
            }}
          >
            <img src={edit_icon} alt="" width={15} />
          </span>
          <span
            className="mx-2 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              showDeleteConfirm(data);
            }}
          >
            <img src={delete_icon} alt="" width={15} />
          </span>
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "_id",
      key: "_id",
      width: "8%",
      render: (text) => (
        <NavLink to={`/event/${text}`} className="flex justify-center">
          <img src={right_arrow_icon} alt="" width={15} />
        </NavLink>
      ),
    },
  ];

  const onChange = (key) => {
    setSearchParams({ tab: key });
    setSelectedKey(key);
  };

  const items = [
    {
      key: "1",
      label: (
        <p className={`${selectedKey === "1" ? "font-semibold" : ""}`}>
          Ongoing Events
        </p>
      ),
      children: selectedKey === "1" && (
        <EventBanner
          data={ongoingEvents}
          loader={isLoading}
          onEdit={onEdit}
          showDeleteConfirm={showDeleteConfirm}
        />
      ),
    },
    {
      key: "2",
      label: (
        <p className={`${selectedKey === "2" ? "font-semibold" : ""}`}>
          Upcoming Events
        </p>
      ),
      children: selectedKey === "2" && (
        <EventBanner
          data={upcomingEvents}
          loader={isLoading}
          onEdit={onEdit}
          showDeleteConfirm={showDeleteConfirm}
        />
      ),
    },
    {
      key: "3",
      label: (
        <p className={`${selectedKey === "3" ? "font-semibold" : ""}`}>
          Past Events
        </p>
      ),
      children: selectedKey === "3" && (
        <EventBanner
          data={pastEvents}
          loader={isLoading}
          onEdit={onEdit}
          showDeleteConfirm={showDeleteConfirm}
        />
      ),
    },
  ];



  return (
    <Container
      selected={"2"}
      header={
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div className="bg-primary h-9 w-9 rounded-full flex justify-center items-center">
              <img src={events_white_icon} width={17} height={17} alt="" />
            </div>
            <p className="ml-2 font-semibold text-3xl text-[#42572A]">Events</p>
          </div>
          <div className="flex">
            <ThemeButton
              onClick={() => setOpen(true)}
              className={"h-[45px]"}
              content={
                <div className="flex justify-between items-center gap-4">
                  <img src={calendar_stack_icon} alt="icon" width={18} height={18} />
                  <p>Add Event</p>
                  <img src={plus_white_icon} alt="icon" width={18} height={18} />
                </div>
              }
            />
          </div>
        </div>
      }
    >
      <EventDrawer
        formData={formData}
        image={image}
        setImage={(e) => setImage(e)}
        setFormData={(e) => setFormData(e)}
        isLoading={addingEvent}
        onSubmit={handleEvent}
        open={open}
        onClose={onClose}
        btnTxt={mode == "create" ? "Add Event" : "Edit Event"}
        addIcon
        resetForm={resetForm}
      />
      {/* {isLoading ? (
        <div
          className="flex justify-center items-center"
          style={{ height: "75vh" }}
        >
          <Spin size="large" />
        </div>
      ) : ( */}
      {/* <Tabs
        defaultActiveKey={searchParams.get("tab") || "1"}
        items={items}
        onChange={onChange}
      /> */}

      <Tabs
        defaultActiveKey={searchParams.get("tab") || "1"}
        activeKey={selectedKey}
        onChange={(key) => setSelectedKey(key)}
        items={items}
      />
      {/* )} */}
      <Modal
        title="Confirm Delete"
        visible={deleteModalVisible}
        onOk={false}
        onCancel={handleDeleteCancel}
        okText={false}
        cancelText={false}
        cancelButtonProps={false}
        okButtonProps={false}
        footer={false}
      >
        <p>Are you sure you want to delete this event?</p>
        <div className="flex justify-end items-center mt-2">
          <ThemeButton
            onClick={handleDeleteCancel}
            className={"ml-3"}
            content={"Cancel"}
          />
          <ThemeButton
            onClick={handleDeleteConfirm}
            className={"ml-3"}
            content={deleteLoagding ? <Spin /> : "Confirm Delete"}
          />
        </div>
      </Modal>
    </Container>
  );
};

export default Event;
