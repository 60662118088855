import { useState, useEffect, useRef, useCallback } from "react";
import React from "react";
import dayjs from "dayjs";
import ReactToPrint from "react-to-print";
import { Tabs, Spin, message, Skeleton, Checkbox, Input } from "antd";
import {
  Container,
  ThemeButton,
  ThemeTable,
  AddDelegateModal,
  ThemeInput,
  SponsorCard,
  AttendeeCard,
  WishlistDrawer,
} from "../../components/components";
import options from "../../lib/data/options.json";
import {
  NavLink,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import {
  left_arrow_icon,
  add_icon,
  right_arrow_icon,
  delete_icon,
  download_icon,
  login_yellow_icon,
  login_icon,
} from "../../assets/icons/icons";
import {
  DownloadOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { user, userFemale } from "../../assets/images/images";
import EventInfo from "./eventInfo/eventInfo";
import AddSponserModal from "../../components/addSponserModal/addSponserModal";
import EventAgenda from "./eventAgenda/eventAgenda";
import {
  useLazyGetEventByIDQuery,
  usePutEventsMutation,
} from "../../services/events";
import {
  useLazyGetUsersQuery,
  useLoginDashboardMutation,
  useSetLoginMutation,
  useUpdateUserMutation,
} from "../../services/auth";
import {
  useLazyGetAttendeesQuery,
  useAddAttendanceMutation,
  useLazyGetAttendanceQuery,
  useRemoveAttendanceMutation,
  useUpdateAttendeeMutation,
} from "../../services/attendees";
import {
  useAddAtendeesMutation,
  useDeleteAtendeesMutation,
} from "../../services/attendees";
import {
  useGetWishlistsQuery,
  useAddWishlistMutation,
  useUpdateWishlistMutation,
} from "../../services/wishlist";
import EventMeetings from "./eventMeetings/eventMeetings";
import SponsorMeetings from "./sponsorMeetings/sponsorMeetings";
import EventBooklet from "./eventpdf/eventpdf";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import QRCode from "qrcode.react";
import { addKeyToStorage, uploadImage } from "../../helpers/helpers";
import EventFeedback from "./feedback/feedback";
import AdditionalMeetings from "./AdditionalMeetings/sponsorMeetings";
import { debounce } from "lodash";
import { Spinner } from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import { ChevronRight } from "lucide-react";

const EventDetails = ({ setAuthUser }) => {
  const cardsRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [getEventByID, { isLoading }] = useLazyGetEventByIDQuery();
  const [getUsers, { isLoading: fetchUsers }] = useLazyGetUsersQuery();
  const [getAttendees, { isLoading: fetchAtendee }] =
    useLazyGetAttendeesQuery();
  const [getAttendance, { isLoading: fetchAttendance }] =
    useLazyGetAttendanceQuery();
  const {
    data: wishlists,
    isLoading: fetchWishlist,
    refetch: refetchWishlist,
  } = useGetWishlistsQuery();
  const [updateUser, { isLoading: updatingUser }] = useUpdateUserMutation();
  const [updateAttendance, { isLoading: updatingAttendance }] =
    useAddAttendanceMutation();
  const [removeAttendance, { isLoading: removingAttendance }] =
    useRemoveAttendanceMutation();
  const [updateEvent, { isLoading: updating }] = usePutEventsMutation();
  const [addAtendees, { isLoading: addingAteendee }] = useAddAtendeesMutation();
  const [deleteAttendees, { isLoading: deletingAttendee }] =
    useDeleteAtendeesMutation();
  const [addWishlist, { isLoading: addingWishlist }] = useAddWishlistMutation();
  const [updateWishlist, { isLoading: updatingWishlist }] =
    useUpdateWishlistMutation();

  const [submitLoader, setSubmitLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [currentPageSponser, setCurrentPageSponser] = useState(1);
  const [resultPerPageSponser, setResultPerPageSponser] = useState(10);
  const [totalSponser, setTotalSponser] = useState(0);
  const [currentPageAttendance, setCurrentPageAttendance] = useState(1);
  const [resultPerPageAttendance, setResultPerPageAttendance] = useState(10);
  const [totalAttendance, setTotalAttendance] = useState(0);
  const [sponserModal, setSponserModal] = useState(false);
  const [addSponserModal, setAddSponserModal] = useState(false);
  const [delegateModal, setDelegateModal] = useState(false);
  const [addDelegateModal, setAddDelegateModal] = useState(false);
  const [selectedKey, setSelectedKey] = useState(tab || "1");
  const [sponsors, setSponsors] = useState([]);
  const [delegates, setDelegates] = useState([]);
  const [sponsorAttendees, setSponsorAttendees] = useState([]);
  const [delegateAttendees, setDelegateAttendees] = useState([]);
  const [delegateSearch, setDelegateSearch] = useState("");
  const [sponsorSearch, setSponsorSearch] = useState("");
  const [isOpenSponsorCard, setIsOpenSponsorCard] = useState(false);
  const [cardData, setCardData] = useState();
  const [attendance, setAttendance] = useState([]);
  const [scannedCode, setScannedCode] = useState("");
  const [isWishListDrawerOpen, setIsWishListDrawerOpen] = useState(false);
  const [wishListFormData, setWishListFormData] = useState({});
  const [isUpdateEventLoader, setIsUpdateEventLoader] = useState(false);

  const [updateAttendee] = useUpdateAttendeeMutation();
  const [sponsorLimits, setSponsorLimits] = useState({});

  const [emailInputs, setEmailInputs] = useState({});


  // ... existing code ...

  useEffect(() => {
    if (delegateAttendees.length > 0 || sponsorAttendees.length > 0) {
      const newEmailInputs = {};
      [...delegateAttendees, ...sponsorAttendees].forEach(attendee => {
        newEmailInputs[attendee.user._id] = attendee.user.email;
      });
      setEmailInputs(newEmailInputs);
    }
  }, [delegateAttendees, sponsorAttendees]);


  const handleSponsorLimitChange = async (attendeeId, newLimit) => {
    // Immediately update the displayed value
    setSponsorLimits((prev) => ({ ...prev, [attendeeId]: newLimit }));

    try {
      const find = sponsorAttendees.find((item) => item._id === attendeeId);
      const response = await updateAttendee({
        id: attendeeId,
        body: { ...find, allowed_meetings: newLimit },
      });
      if (response.data?.status === 200) {
        message.success(" Limit updated successfully");
        getEvent(); // Refresh the event data
      } else {
        // Revert the displayed value if the API call fails
        setSponsorLimits((prev) => ({
          ...prev,
          [attendeeId]: prev[attendeeId] - (newLimit - prev[attendeeId]),
        }));
        message.error("Failed to update  Limit");
      }
    } catch (error) {
      // Revert the displayed value if there's an error
      setSponsorLimits((prev) => ({
        ...prev,
        [attendeeId]: prev[attendeeId] - (newLimit - prev[attendeeId]),
      }));
      console.error("Error updating  limit:", error);
      message.error("An error occurred while updating  limit");
    }
  };
  const handleSearchChangeSponsor = useCallback(
    debounce((value) => {
      setSponsorSearch(value);
    }, 300), // Adjust the debounce delay as needed
    []
  );

  const handleInputChangeSponsor = (e) => {
    const value = e.target.value;
    handleSearchChangeSponsor(value);
  };

  const [formData, setFormData] = useState({
    name: "",
    banner: "",
    start_date: null,
    end_date: null,
    location: "",
    discussion: "",
    speakers: "",
    meetings: "",
    networking_hours: "",
    booths: "",
    description: "",
  });
  const [eventBanner, setEventBanner] = useState("");
  const [delegateForm, setDelegateForm] = useState({});
  const [sponsorForm, setSponsorForm] = useState({});
  const [searchCompanyName, setSearchCompanyName] = useState("");

  let debounceTimeout;

  const getDay = () => {
    let day = "";
    const currentDateTime = dayjs();
    const startDatePlusOneDay = dayjs(formData.start_date).add(1, "day");

    if (currentDateTime.isAfter(startDatePlusOneDay)) {
      day = "Day 2";
    } else if (currentDateTime.isAfter(formData?.start_date)) {
      day = "Day 1";
    }
    return day;
  };

  console.log("delegate attendance", delegateAttendees);

  const filterByCompanyName = delegateAttendees.filter(
    (item) => item.user.metadata.delegate_details.company !== searchCompanyName
  );
  // const {
  //   attendance,
  //   isLoading: fetchAttendance,
  //   refetch,
  // } = useGetAttendanceQuery({
  //   currentPageAttendance,
  //   resultPerPageAttendance,
  //   eventID: id,
  //   userType: "delegate,sponsor",
  //   attendance: getDay(),
  // });

  const [loginDashboard, { isLoading: isLoadingLogin, data, error }] =
    useLoginDashboardMutation();

  const handleLogin = async (data) => {
    const { user } = data;

    if (user?.email) {
      try {
        // Perform login and retrieve response
        const response = await loginDashboard({ email: user.email });
        if (response?.data?.status === 200) {
          const userType = response.data.user.user_type;
          const token = response.data.token;

          // Set session storage data in the original tab
          sessionStorage.setItem(`${userType}Token`, token);
          sessionStorage.setItem("userType", userType);
          sessionStorage.setItem("user", response.data.user._id);

          // Open the new tab
          const newTab = window.open("/upcoming-events", "_blank");

          if (newTab) {
            // Send the session data to the new tab
            const payload = {
              userType,
              token,
              userId: response.data.user._id,
            };

            newTab.onload = () => {
              newTab.postMessage(payload, window.location.origin);

              // Clear the session storage in the original tab
              sessionStorage.removeItem(`${userType}Token`);
              sessionStorage.removeItem("userType");
              sessionStorage.removeItem("user");
            };
          }
        } else {
          message.error(response?.error?.data?.message || "Login failed");
        }
      } catch (error) {
        console.error("Login error:", error);
        message.error("An unexpected error occurred");
      }
    } else {
      message.error("Email and password are required");
    }
  };

  useEffect(() => {
    getEvent();
  }, []);

  useEffect(() => {
    getAllUsers();
  }, [delegateAttendees, sponsorAttendees]);

  useEffect(() => {
    getAllAttendance();
  }, []);

  useEffect(() => {
    if (scannedCode) {
      handleAttendance();
    }
  }, [scannedCode]);

  const getEvent = async () => {
    const response = await getEventByID(id);
    const event = response?.data?.events?.data[0];
    // console.log('response-->', response?.data?.events?.data[0])
    const attendees = event.attendees;
    const attendeesDelegates = [];
    const attendeesSponsors = [];
    for (var i = 0; i < attendees.length; i++) {
      if (attendees[i]?.user?.user_type === "sponsor") {
        attendeesSponsors.push(attendees[i]);
      } else if (attendees[i]?.user?.user_type === "delegate") {
        attendeesDelegates.push(attendees[i]);
      }
    }
    setDelegateAttendees(attendeesDelegates);
    setSponsorAttendees(attendeesSponsors);
    setFormData({
      ...event,
      start_date: dayjs(event?.start_date),
      end_date: dayjs(event?.end_date),
    });
    setEventBanner(event?.banner);
  };

  const getAllUsers = async () => {
    const response = await getUsers({
      currentPage,
      resultPerPage: 1000000,
      userType: "delegate,sponsor",
    });
    const users = response?.data?.users?.data || [];
    const delegates = [];
    const sponsors = [];
    for (var i = 0; i < users.length; i++) {
      if (users[i].user_type === "sponsor") {
        const index = sponsorAttendees.findIndex(
          (v) => v.user._id == users[i]._id
        );
        if (index === -1) {
          sponsors.push({ ...users[i] });
        }
      } else if (users[i].user_type === "delegate") {
        const index = delegateAttendees.findIndex(
          (v) => v.user._id == users[i]._id
        );
        if (index === -1) {
          delegates.push({ ...users[i] });
        }
      }
    }
    setDelegates(delegates);
    setSponsors(sponsors);
  };

  const getAllAttendance = async () => {
    const response = await getAttendance({
      currentPageAttendance,
      resultPerPageAttendance,
      eventID: id,
      userType: "delegate,sponsor",
    });
    setAttendance(response?.data?.attendees?.data || []);
    // console.log('attendance', response)
  };

  const deleteAttendee = async (data) => {
    try {
      const response = await deleteAttendees(data?._id);
      if (response?.data?.status === 200) {
        getEvent();
        message.success(response?.data?.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkAttendance = (userId, day) => {
    let isUserAtendeed = attendance?.find((item) => item?.user?._id === userId);
    if (isUserAtendeed) {
      let isAttendance = isUserAtendeed?.attendance.indexOf(day) !== -1;
      return isAttendance;
    } else {
      return false;
    }
  };

  const handleEmailChange = debounce(async (newEmail, userId) => {
    try {
      const response = await getUsers({ userId });

      if (response?.data?.users?.data?.[0]) {
        const currUser = response.data.users.data[0];

        await updateUser({
          id: userId,
          body: {
            ...currUser,
            email: newEmail,
          },
        });

        setEmailInputs(prev => ({ ...prev, [userId]: newEmail }));
        message.success(`Email Updated Successfully`);
      } else {
        message.error("User not found or invalid response format");
      }
    } catch (error) {
      console.error("Error updating user email:", error);
      message.error("Error updating user email");
    }
  }, 800);
  const sponserColumns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (data, _, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "ID",
      dataIndex: "user.picture",
      key: "picture",
      render: (_, data) => (
        <div className="flex justify-center">
          <img
            src={
              data?.user?.picture ||
              (data?.user?.gender === "male" ? user : userFemale)
            }
            alt="Profile"
            width={45}
            height={45}
            className="w-[45px] h-[45px] rounded-full object-cover"
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (_, data) => (
        <p className="text-center font-medium">{`${data?.user?.first_name} ${data?.user?.last_name}`}</p>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, data) => (
        <Input
          value={emailInputs[data?.user?._id] || ''}
          onChange={(e) => {
            setEmailInputs(prev => ({ ...prev, [data.user._id]: e.target.value }));
            handleEmailChange(e.target.value, data?.user?._id);
          }}
          className="text-center font-medium"
        />
      ),
    },
    {
      title: "Attendance",
      dataIndex: "attendance",
      key: "attendance",
      render: (_, data) => (
        <div className="flex justify-center">
          <Checkbox
            checked={checkAttendance(data?.user?._id, "Day 1")}
            value="Day 1"
            onChange={(e) =>
              updateAttendeeAttendance(e.target, data?.user?._id, "sponsor")
            }
          >
            Day 1
          </Checkbox>
          <Checkbox
            checked={checkAttendance(data?.user?._id, "Day 2")}
            value="Day 2"
            onChange={(e) =>
              updateAttendeeAttendance(e.target, data?.user?._id, "sponsor")
            }
          >
            Day 2
          </Checkbox>
        </div>
      ),
    },
    {
      title: "Allowed Meetings",
      dataIndex: "allowed_meetings",
      key: "allowed_meetings",
      render: (_, data) => {
        const currentLimit =
          sponsorLimits[data._id] ?? data.allowed_meetings ?? 0;
        return (
          <div className="flex items-center justify-center">
            <ThemeButton
              content={<MinusOutlined />}
              onClick={() =>
                handleSponsorLimitChange(data._id, currentLimit - 1)
              }
              className="mr-2"
              disabled={currentLimit <= 0}
            />
            <span className="mx-2 font-medium">{currentLimit}</span>
            <ThemeButton
              content={<PlusOutlined />}
              onClick={() =>
                handleSponsorLimitChange(data._id, currentLimit + 1)
              }
              className="ml-2"
            />
          </div>
        );
      },
    },
    {
      title: "In Action",
      dataIndex: "inaction",
      key: "inaction",
      render: (_, data) => (
        <div className="flex justify-center">


          <ThemeButton
            content={<img src={download_icon} alt="delete-icon" />}
            className={"!bg-transparent"}
            onClick={() => {
              setCardData(data);
              setIsOpenSponsorCard(true);
            }}
          />
          <ThemeButton
            onClick={() => deleteAttendee(data)}
            content={<img src={delete_icon} alt="delete-icon" />}
            className={"!bg-transparent"}
          />

          <ThemeButton
            onClick={() => handleLogin(data)}
            className={"!bg-transparent"}
            content={<img src={login_yellow_icon} alt="delete-icon"
            />}
          />
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "inaction",
      key: "inaction",
      width: "8%",
      render: (_, data) => (
        <ThemeButton
          onClick={() => navigate(`/${data?.user?.user_type}/profile/${data?.user?._id}/${data.event}`)}
          content={
            <div className="flex justify-center items-center">
              <p>See details</p>
              <ChevronRight size={18} />
            </div>
          }

        />
        // <NavLink
        //   to={`/${data?.user?.user_type}/profile/${data?.user?._id}/${data.event}`}
        //   className="flex justify-center"
        // >
        //   <img src={right_arrow_icon} alt="" width={15} />
        // </NavLink>
      ),
    },
  ];

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (data, _, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "ID",
      dataIndex: "picture",
      key: "picture",
      render: (_, data) => (
        <div className="flex justify-center">
          <img
            src={
              data?.user?.picture ||
              (data?.user?.gender === "male" ? user : userFemale)
            }
            alt=""
            width={45}
            height={45}
            className="w-[45px] h-[45px] rounded-full object-cover"
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (_, data) => (
        <p className="text-center font-medium">{`${data?.user?.first_name} ${data?.user?.last_name}`}</p>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, data) => (
        <Input
          value={emailInputs[data?.user?._id] || ''}
          onChange={(e) => {
            setEmailInputs(prev => ({ ...prev, [data.user._id]: e.target.value }));
            handleEmailChange(e.target.value, data?.user?._id);
          }}
          className="text-center font-medium"
        />
      ),
    },

    {
      title: "Attendance",
      dataIndex: "attendance",
      key: "attendance",
      render: (_, data) => (
        <div className="flex justify-center">
          <Checkbox
            checked={checkAttendance(data?.user?._id, "Day 1")}
            value="Day 1"
            onChange={(e) =>
              updateAttendeeAttendance(e.target, data?.user?._id, "delegate")
            }
          >
            Day 1
          </Checkbox>
          <Checkbox
            checked={checkAttendance(data?.user?._id, "Day 2")}
            value="Day 2"
            onChange={(e) =>
              updateAttendeeAttendance(e.target, data?.user?._id, "delegate")
            }
          >
            Day 2
          </Checkbox>
        </div>
      ),
    },

    {
      title: "In Action",
      dataIndex: "inaction",
      key: "inaction",
      render: (_, data) => (
        <div className="flex justify-center gap-2">
          <ThemeButton
            onClick={() => deleteAttendee(data)}
            content={<img src={delete_icon} alt="delete-icon" />}
            className={"!bg-transparent"}
          />
          <ThemeButton
            content={<img src={download_icon} alt="download-icon" />}
            // className={"mx-1"}
            className={"!bg-transparent"}

            onClick={() => {
              setCardData(data);
              setIsOpenSponsorCard(true);
            }}
          />
          <ThemeButton
            onClick={() => handleLogin(data)}
            className={"!bg-transparent"}
            content={<img src={login_yellow_icon} alt="login-icon"
            />} />
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "inaction",
      key: "inaction",
      width: "8%",
      render: (_, data) => (
        <ThemeButton
          onClick={() => navigate(`/${data?.user?.user_type}/profile/${data?.user?._id}/${data.event}`)}
          content={
            <div className="flex justify-center items-center">
              <p>See details</p>
              <ChevronRight size={18} />
            </div>
          }

        />
        // <NavLink
        //   to={`/${data?.user?.user_type}/profile/${data?.user?._id}/${data.event}`}
        //   className="flex justify-center"
        // >
        //   <img src={right_arrow_icon} alt="" width={15} />
        // </NavLink>
      ),
    },
  ];

  const attendanceColumns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (data, _, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "ID",
      dataIndex: "user.picture",
      key: "picture",
      render: (_, data) => (
        <div className="flex justify-center">
          <img
            src={
              data?.user?.picture ||
              (data?.gender === "male" ? user : userFemale)
            }
            alt=""
            width={45}
            height={45}
            className="w-[45px] h-[45px] rounded-full object-cover"
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (_, data) => (
        <p className="text-center font-medium">{`${data?.user?.first_name} ${data?.user?.last_name}`}</p>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, data) => (
        <p className="text-center font-medium">{data?.user?.email}</p>
      ),
    },
    {
      title: "Day 1",
      dataIndex: "day1",
      key: "day1",
      render: (_, data) => (
        <span className="flex justify-center text-xl">
          {data?.attendance && data?.attendance.indexOf("Day 1") !== -1 ? (
            <CheckCircleOutlined className="text-green-700" />
          ) : (
            <CloseCircleOutlined className="text-red-700" />
          )}
        </span>
      ),
    },
    {
      title: "Day 2",
      dataIndex: "day2",
      key: "day2",
      render: (_, data) => (
        <span className="flex justify-center text-xl">
          {getDay() !== "Day 1" ? (
            data?.attendance && data?.attendance.indexOf("Day 2") !== -1 ? (
              <CheckCircleOutlined className="text-green-700" />
            ) : (
              <CloseCircleOutlined className="text-red-700" />
            )
          ) : (
            "-"
          )}
        </span>
      ),
    },
  ];

  const wishlistColumns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (data, _, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "Sponsor",
      dataIndex: "sender",
      key: "sender",
      render: (sender) => (
        <p className="text-center font-medium">{`${sender?.first_name || ""} ${sender?.last_name || ""
          }`}</p>
      ),
    },
    {
      title: "Delegate",
      dataIndex: "receiver",
      key: "receiver",
      render: (receiver) => (
        <p className="text-center font-medium">{`${receiver?.first_name || ""
          } ${receiver?.last_name || ""}`}</p>
      ),
    },
    {
      title: "",
      dataIndex: "inaction",
      key: "inaction",
      render: (_, data) => (
        <div className="flex justify-center">
          <ThemeButton
            content={<CheckCircleOutlined className="text-green-600 text-lg" />}
            onClick={() =>
              onUpdateMeetingStatus(data?._id, { isMeetingDone: true })
            }
            className={"!bg-transparent"}
          />
          {!data?.isMeetingDone && (
            <ThemeButton
              content={<CloseCircleOutlined className="text-red-600 text-lg" />}
              onClick={() =>
                onUpdateMeetingStatus(data?._id, { isMeetingDone: false })
              }
              className={"!bg-transparent"}
            />
          )}
        </div>
      ),
    },
  ];

  const handleSearchChange = useCallback(
    debounce((value) => {
      setDelegateSearch(value);
      // refetch(); // Trigger API call when user stops typing
    }, 300), // Adjust the debounce delay as needed
    []
  );

  const handleCompanySearchChange = useCallback(
    debounce((value) => {
      setSearchCompanyName(value);
      // refetch(); // Trigger API call when user stops typing
    }, 300), // Adjust the debounce delay as needed
    []
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    handleSearchChange(value);
  };

  const handleInputChangeSearch = (e) => {
    const value = e.target.value;
    handleCompanySearchChange(value);
  };

  const onOpenAddDelegateModal = (data) => {
    setDelegateForm(data);
    setAddDelegateModal(true);
  };

  const onCloseAddDelegateModal = () => {
    setDelegateForm({
      user: "",
      event: "",
      status: "invited",
      delegate_user: {
        email: "",
        first_name: "",
        last_name: "",
        email: "",
        picture: "",
        user_type: "delegate",
      },
      metadata: {
        delegate_details: {
          company: "",
          position: "",
          country: "",
          post_code: "",
          fax: "",
          telephone: "",
          mobile: "",
          address: "",
        },
        atendee_details: {
          name: "",
          position: "",
          email_and_tel: "",
          company: "",
        },
        extra_details: {
          name: "",
          position: "",
          line_manager: "",
          priorities: "",
          intrest_list: "",
          indutrial_sector: null,
          ap_budget: null,
          scope_for_resposibility: null,
          products_and_services: null,
        },
      },
    });
    setAddDelegateModal(false);
  };

  const onOpenSponserModal = (data) => {
    setSponsorForm(data);
    setAddSponserModal(true);
  };

  const onCloseAddSponserModal = () => {
    setSponsorForm({
      user: "",
      event: "",
      status: "invited",
      allowed_meetings: "",
      sponsor_user: {
        email: "",
        first_name: "",
        last_name: "",
        picture: "",
        user_type: "sponsor",
      },
      metadata: {
        delegate_details: {
          company: "",
          profession: "",
          phone: "",
          address: "",
        },
        atendee_details: {
          title: "",
          table_number: "",
        },
        package_details: {
          sponsorship: null,
          customize_details: "",
          investment: "",
          due_date: "",
        },
      },
    });
    setAddSponserModal(false);
  };

  const onCloseWishlistDrawer = () => {
    setWishListFormData({});
    setIsWishListDrawerOpen(false);
  };

  const updateEventDetails = async () => {
    setIsUpdateEventLoader(true);
    const url = await uploadImage(formData.banner);
    let data = {
      ...formData,
      banner: url,
      start_date: formData?.start_date?.$d
        ? new Date(formData.start_date.$d)
        : null,
      end_date: formData?.end_date?.$d ? new Date(formData.end_date.$d) : null,
    };
    if (data.start_date) {
      data.start_date.setHours(0, 0, 0, 0);
      data.start_date = data.start_date.toISOString();
    }
    if (data.end_date) {
      data.end_date.setHours(23, 59, 59, 59);
      data.end_date = data.end_date.toISOString();
    }
    delete data?.attendees;
    delete data?.schedules;
    console.log("updateEventDetails->", data, "id-->", id);
    await updateEvent({
      id: id,
      body: data,
    });
    setIsUpdateEventLoader(false);
    message.success("Updated Successfully");
  };

  const onSubmitDelegate = async () => {
    console.log(delegateForm, "delegateForm");
    setSubmitLoader(true);
    let data = {
      user: delegateForm._id,
      event: id,
    };
    await updateUser({
      id: delegateForm?._id,
      body: {
        ...delegateForm,
        metadata: { ...delegateForm?.metadata },
      },
    });
    const response = await addAtendees(data);
    if (response?.data?.status == 200) {
      message.success("Added Successfully");
      setSubmitLoader(false);
      onCloseAddDelegateModal();
      getEvent();
    } else {
      setSubmitLoader(false);
      message.error(
        response?.error?.data?.message || "Please fill form correctlly"
      );
    }
  };

  const onSubmitSponsor = async () => {
    console.log(sponsorForm, "sponsorForm");
    try {
      setSubmitLoader(true);
      const userResponse = await updateUser({
        id: sponsorForm?._id,
        body: {
          metadata: { ...sponsorForm?.metadata },
        },
      });
      let data = {
        user: sponsorForm?._id,
        event: id,
        allowed_meetings: Number(sponsorForm.allowed_meetings),
      };
      const response = await addAtendees(data);
      if (response?.data?.status == 200) {
        message.success("Added Successfully");
        setSubmitLoader(false);
        onCloseAddSponserModal();
        getEvent();
      } else {
        setSubmitLoader(false);
        message.error("Please fill form correctlly");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmitWishlist = async () => {
    try {
      const response = await addWishlist({ ...wishListFormData, event: id });
      if (response?.data?.status == 200) {
        message.success("Wishlist Added Successfully");
        onCloseWishlistDrawer();
        refetchWishlist();
      } else {
        message.error(
          response?.error?.data?.message || "Please fill form correctly"
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onUpdateMeetingStatus = async (id, body) => {
    const response = await updateWishlist({ id, body });
    if (response?.data?.status == 200) {
      message.success("Updated Successfully");
      refetchWishlist();
    } else
      message.error(
        response?.error?.data?.message || "Please fill form correctly"
      );
  };

  const onChange = (key) => {
    setSearchParams({ tab: key });
    setSelectedKey(key);
  };

  const handleAttendance = async () => {
    let day = getDay();

    if (day) {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(async () => {
        if (scannedCode) {
          try {
            const response = await updateAttendance({
              id: scannedCode,
              body: { day: day, event: id, attendance: true },
            });
            console.log("res", response);
            if (!response?.error) {
              message.success("Attendance Successfull");
              setScannedCode("");
              getAllAttendance();
            } else message.error("User not found!");
          } catch (err) {
            console.log(err);
          }
        }
      }, 500);
    } else {
      message.error("Attendance mark only in event time");
    }
  };

  const updateAttendeeAttendance = async (target, userId, type) => {
    console.log("target.checked-->", target.checked, target.value);
    try {
      if (type === "sponsor") {
        console.log(
          sponsorAttendees.find((data) => data?.user?._id === userId)
        );
        // console.log() sponsorAttendees.find((data)=> (data?.user?._id === userId))?.attendance?.includes(target.value)
      }
      if (target.checked) {
        const response = await updateAttendance({
          id: userId,
          body: { day: target.value, event: id, attendance: target.checked },
        });
      } else {
        const response = await removeAttendance({
          id: userId,
          body: { attendance: target.value, event: id },
        });
      }
      getAllAttendance();
    } catch (err) {
      console.log(err);
    }
  };

  const filteredData = sponsorAttendees?.map((item, index) => {
    return {
      No: index + 1,
      AttendeeName: item.user.first_name + " " + item.user.last_name,
      CompanyName: item?.user?.metadata?.delegate_details?.company,
      Contact: item?.user?.metadata?.delegate_details?.phone,
      Email: item.user.email,
      JobTitle: item?.user?.metadata?.delegate_details?.position,
    };
  });

  console.log(sponsorAttendees, "sponsorAttendees");

  const filteredDelegateAttendees = delegateAttendees?.map((item, index) => {
    return {
      No: index + 1,
      AttendeeName: item.user.first_name + " " + item.user.last_name,
      CompanyName: item?.user?.metadata?.delegate_details?.company,
      Contact: item?.user?.metadata?.delegate_details?.phone,
      Email: item.user.email,
      JobTitle: item?.user?.metadata?.delegate_details?.position,
    };
  });

  console.log(delegateAttendees, "filteredDelegateAttendees");

  console.log(filteredData, "filteredData");
  console.log(sponsorAttendees, "sponsorAttendees");

  const items = [
    {
      key: "1",
      label: (
        <p className={`${selectedKey === "1" && "font-semibold"}`}>
          Event Info
        </p>
      ),
      children: (
        <div>
          {isLoading ? (
            <div className="bg-white rounded-xl p-5 mb-8">
              <Skeleton />
            </div>
          ) : (
            <EventInfo
              formData={formData}
              isLoading={isLoading}
              setFormData={(e) => setFormData(e)}
              image={eventBanner}
              setImage={(e) => setEventBanner(e)}
            />
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <p className={`${selectedKey === "2" && "font-semibold"}`}>
          Event Agenda
        </p>
      ),
      children: (
        <EventAgenda isAdmin={true} eventID={id} eventName={formData?.name} />
      ),
    },
    {
      key: "3",
      label: (
        <p className={`${selectedKey === "3" && "font-semibold"}`}>Delegates</p>
      ),
      children: (
        <div>
          <div className="py-2 ">
            <ThemeInput
              onChange={handleInputChange}
              className="mb-1 "
              placeholder="Search delegates"
            />
          </div>
          <ThemeTable
            columns={columns}
            data={delegateAttendees.filter(
              (data) =>
                data.user.email
                  .toLowerCase()
                  .startsWith(delegateSearch.toLowerCase()) ||
                data.user.first_name
                  .toLowerCase()
                  .startsWith(delegateSearch.toLowerCase()) ||
                data.user.last_name
                  .toLowerCase()
                  .startsWith(delegateSearch.toLowerCase()) ||
                data.user.metadata.delegate_details.company
                  .toLowerCase()
                  .startsWith(delegateSearch.toLowerCase())
            )}
            pagination={{ total, setCurrentPage, setResultPerPage }}
            loader={fetchUsers}
          />
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <p className={`${selectedKey === "4" && "font-semibold"}`}>Sponsors</p>
      ),
      children: (
        <div>
          <div className="py-2">
            <ThemeInput
              onChange={handleInputChangeSponsor}
              placeholder="Search sponsors"
            />
          </div>
          <ThemeTable
            columns={sponserColumns}
            data={sponsorAttendees.filter(
              (data) =>
                data.user.email
                  .toLowerCase()
                  .startsWith(sponsorSearch.toLowerCase()) ||
                data.user.first_name
                  .toLowerCase()
                  .startsWith(sponsorSearch.toLowerCase()) ||
                data.user.last_name
                  .toLowerCase()
                  .startsWith(sponsorSearch.toLowerCase())
            )}
            pagination={{
              total: totalSponser,
              setCurrentPage: setCurrentPageSponser,
              setResultPerPage: setResultPerPageSponser,
            }}
            loader={fetchUsers}
          />
        </div>
      ),
    },
    // {
    //   key: "5",
    //   label: (
    //     <p className={`${selectedKey === "5" && "font-semibold"}`}>
    //       All Meetings
    //     </p>
    //   ),
    //   children: (
    //     <EventMeetings isAdmin={true} eventID={id} eventName={formData?.name} />
    //   ),
    // },
    {
      key: "5",
      label: (
        <p className={`${selectedKey === "5" && "font-semibold"}`}>
          All Meetings
        </p>
      ),
      children: (
        <SponsorMeetings
          isAdmin={true}
          eventID={id}
          eventName={formData?.name}
          attendance={attendance}
        />
      ),
    },
    {
      key: "6",
      label: (
        <p className={`${selectedKey === "6" && "font-semibold"}`}>
          Additional Meetings
        </p>
      ),
      children: (
        <AdditionalMeetings
          isAdmin={true}
          eventID={id}
          eventName={formData?.name}
        />
      ),
    },
    {
      key: "7",
      label: (
        <p className={`${selectedKey === "7" && "font-semibold"}`}>
          Event Booklet
        </p>
      ),
      children: (
        <EventBooklet isAdmin={true} eventID={id} eventName={formData?.name} />
      ),
    },
    {
      key: "8",
      label: (
        <p className={`${selectedKey === "8" && "font-semibold"}`}>
          Attendance
        </p>
      ),
      children: (
        <div>
          <div className="py-2">
            <ThemeInput
              value={scannedCode}
              onChange={(e) => setScannedCode(e.target.value)}
              placeholder="scan qr code here..."
            />
          </div>
          <ThemeTable
            columns={attendanceColumns}
            data={attendance}
            pagination={{
              total: totalAttendance,
              setCurrentPage: setCurrentPageAttendance,
              setResultPerPage: setResultPerPageAttendance,
            }}
            loader={fetchAtendee}
          />
        </div>
      ),
    },
    {
      key: "9",
      label: (
        <p className={`${selectedKey === "9" && "font-semibold"}`}>
          Event Feedback
        </p>
      ),
      children: (
        <EventFeedback isAdmin={true} eventID={id} eventName={formData?.name} />
      ),
    },
    {
      key: "10",
      label: (
        <p className={`${selectedKey === "10" && "font-semibold"}`}>
          Wishlists
        </p>
      ),
      children: (
        <div className="py-2">
          <ThemeTable
            columns={wishlistColumns}
            data={wishlists?.wishlist?.data}
            loader={fetchWishlist}
          />
        </div>
      ),
    },
  ];

  if (fetchWishlist) {
    <>
      <Spinner />
    </>;
  }

  return (
    <Container
      selected={"2"}
      header={
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <NavLink to="/event">
              <img src={left_arrow_icon} width={18} height={18} alt="" />
            </NavLink>
            <p className="ml-3 font-semibold text-2xl">{formData?.name}</p>
          </div>
          {selectedKey === "1" && (
            <ThemeButton
              className={"w-32"}
              content={isUpdateEventLoader ? <Spin /> : "Update Info"}
              onClick={updateEventDetails}
            />
          )}
          {selectedKey === "3" && (
            <div className="flex items-center gap-4">
              <ThemeButton
                content={
                  <div className="flex items-center">
                    <p>
                      <CSVLink
                        data={filteredDelegateAttendees}
                        filename={"delegate-data.csv"}
                        className="hover:text-white"
                        target="_blank"
                      >
                        Download CSV
                      </CSVLink>
                    </p>
                  </div>
                }
              />
              <ThemeButton
                onClick={() => setDelegateModal(true)}
                content={
                  <div className="flex items-center">
                    <img src={add_icon} alt="" width={18} height={18} />
                    <p className="pl-2">Add Delegate</p>
                  </div>
                }
              />
              <div>
                <ReactToPrint
                  trigger={() => (
                    <ThemeButton
                      content={
                        <div className="flex items-center">
                          <DownloadOutlined className="text-white text-base" />
                          <p className="pl-2">Download All Card</p>
                        </div>
                      }
                    />
                  )}
                  content={() => cardsRef.current}
                />
                <div style={{ display: "none" }}>
                  <AttendeeCard
                    cards={delegateAttendees}
                    eventData={formData}
                    ref={cardsRef}
                  />
                </div>
              </div>
            </div>
          )}
          {selectedKey === "4" && (
            <div className="flex items-center gap-4">
              <ThemeButton
                content={
                  <div className="flex items-center ">
                    <p>
                      <CSVLink
                        data={filteredData}
                        filename={"sponsor-data.csv"}
                        className="hover:text-white"
                        target="_blank"
                      >
                        Download CSV
                      </CSVLink>
                    </p>
                  </div>
                }
              />
              <ThemeButton
                onClick={() => setSponserModal(true)}
                content={
                  <div className="flex items-center">
                    <img src={add_icon} alt="" width={18} height={18} />
                    <p className="pl-2">Add Sponser</p>
                  </div>
                }
              />

              <div>
                <ReactToPrint
                  trigger={() => (
                    <ThemeButton
                      content={
                        <div className="flex items-center">
                          <DownloadOutlined className="text-white text-base" />
                          <p className="pl-2">Download All Card</p>
                        </div>
                      }
                    />
                  )}
                  content={() => cardsRef.current}
                />
                <div style={{ display: "none" }}>
                  <AttendeeCard
                    cards={sponsorAttendees}
                    eventData={formData}
                    ref={cardsRef}
                  />
                </div>
              </div>
            </div>
          )}
          {selectedKey === "9" && (
            <ThemeButton
              onClick={() => setIsWishListDrawerOpen(true)}
              content={
                <div className="flex items-center">
                  <img src={add_icon} alt="" width={18} height={18} />
                  <p className="ml-2">Add Wishlist</p>
                </div>
              }
            />
          )}
        </div>
      }
    >
      <AddDelegateModal
        delegates={delegates}
        isModalOpen={delegateModal}
        onCloseDelegateModal={(e) => setDelegateModal(false)}
        addDelegateModal={addDelegateModal}
        onOpenAddDelegateModal={onOpenAddDelegateModal}
        onClose={onCloseAddDelegateModal}
        formData={delegateForm}
        setFormData={(e) => setDelegateForm(e)}
        onSubmit={onSubmitDelegate}
        isLoading={submitLoader}
        options={options}
      />
      <AddSponserModal
        sponsors={sponsors}
        isModalOpen={sponserModal}
        onCloseSponsorModal={(e) => setSponserModal(false)}
        addSponserModal={addSponserModal}
        onOpenSponserModal={onOpenSponserModal}
        onClose={onCloseAddSponserModal}
        formData={sponsorForm}
        setFormData={(e) => setSponsorForm(e)}
        onSubmit={onSubmitSponsor}
        isLoading={submitLoader}
        options={options}
      />
      <WishlistDrawer
        open={isWishListDrawerOpen}
        onClose={onCloseWishlistDrawer}
        btnTxt={"Add Wishlist"}
        addIcon
        onSubmit={onSubmitWishlist}
        isLoading={addingWishlist}
        formData={wishListFormData}
        setFormData={(e) => setWishListFormData(e)}
        sponsors={sponsorAttendees}
        delegates={delegateAttendees}
      />
      <SponsorCard
        open={isOpenSponsorCard}
        onClose={() => setIsOpenSponsorCard(false)}
        data={cardData}
        eventData={formData}
      />
      <Tabs
        defaultActiveKey={searchParams.get("tab") || "1"}
        items={items}
        onChange={onChange}
      />
    </Container>
  );
};

export default EventDetails;
