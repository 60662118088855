import { Row, Col, DatePicker, Input, Spin, Select } from "antd";
import { ImageUpload, ThemeInput } from "../../../components/components";
import { calender_colored_icon, calender_icon, camera_icon, clock_icon, location_icon } from "../../../assets/icons/icons";
import dayjs from "dayjs";
import Banner from "../../../components/banner/banner";

const EventInfo = ({ formData, image, setImage, isLoading, setFormData }) => {
  const onChangeStartDate = (date) => {
    setFormData({
      ...formData,
      start_date: date,
    });
  };
  const onChangeEndDate = (date) => {
    setFormData({
      ...formData,
      end_date: date,
    });
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(URL.createObjectURL(selectedImage));
    setFormData({
      ...formData,
      banner: selectedImage,
    });
  };
  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <div className="rounded-xl">
          {/* Event Banner */}
          <Banner data={formData} onChange={(e) => onImageChange(e)} />
          {/* Event Form */}
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="flex items-center">
            {/* <Col span={6}>
              <ImageUpload image={image} onChange={(e) => onImageChange(e)} />
            </Col> */}
            <Col span={24} className="mb-4">
              <ThemeInput
                name={"name"}
                onChange={handleChange}
                label={"Event Name"}
                value={formData?.name}
                placeholder={"Event Name"}
              />
            </Col>
            <Col span={8}>
              <p className="font-semibold mb-1">Start Date</p>
              <DatePicker
                value={formData.start_date}
                onChange={onChangeStartDate}
                className="w-full h-10 font-medium"
                suffixIcon={
                  <img src={calender_colored_icon} alt="" width={20} />
                }
              />
            </Col>
            <Col span={8}>
              <p className="font-semibold mb-1">End Date</p>
              <DatePicker
                value={formData.end_date}
                onChange={onChangeEndDate}
                className="w-full h-10 font-medium"
                suffixIcon={
                  <img src={calender_colored_icon} alt="" width={20} />
                }
              />
            </Col>
            <Col span={8}>
              <ThemeInput
                name={"location"}
                onChange={handleChange}
                label={"Event Location"}
                value={formData?.location}
                placeholder={"Event Location"}
              />
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <ThemeInput
                name={"discussion"}
                onChange={handleChange}
                label={"Panel Discussions"}
                value={formData?.discussion}
                placeholder={"Panel Discussions"}
              />
            </Col>
            <Col span={8}>
              <ThemeInput
                name={"speakers"}
                onChange={handleChange}
                label={"Speakers"}
                value={formData?.speakers}
                placeholder={"Speakers"}
              />
            </Col>
            <Col span={8}>
              <ThemeInput
                name={"meetings"}
                label={"Meetings"}
                onChange={handleChange}
                value={formData?.meetings}
                placeholder={"Meetings"}
              />
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <p className="font-semibold mb-1">Event Hall</p>
              <Select
                placeholder="Select Event Hall"
                className="w-full font-semibold"
                size="large"
                name={"event_hall"}
                onChange={handleChange}
                value={formData?.event_hall}
              >
                <Select.Option value="single-hall">
                  Single Hall Event
                </Select.Option>
                <Select.Option value="multi-hall">
                  Multi Hall Event
                </Select.Option>
              </Select>
            </Col>
            <Col span={8}>
              <ThemeInput
                name={"networking_hours"}
                label={"Networking Hours"}
                onChange={handleChange}
                value={formData?.networking_hours}
                placeholder={"Networking Hours"}
              />
            </Col>
            <Col span={8}>
              <ThemeInput
                name={"booths"}
                onChange={handleChange}
                label={"Exhibition Booths"}
                value={formData?.booths}
                placeholder={"Exhibition Booths"}
              />
            </Col>
          </Row>
          <div className="mt-4">
            <p className="font-semibold mb-1">Event Description</p>
            <Input.TextArea
              name="description"
              onChange={handleChange}
              placeholder="Event Description"
              rows={5}
              value={formData?.description}
              className="font-medium pt-2"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default EventInfo;
