import SideBar from "../sideBar/sideBar";

const Container = ({ children, header, selected }) => {
  return (
    <div className="flex bg-[#FBFBFB] gap-10">
      <div style={{ width: 400 }} className=" min-h-screen">
        <SideBar selected={selected} />
      </div>
      <div className="px-5 py-4" style={{ width: "100%" }}>
        <div className="mb-6">{header}</div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Container;
