import { useEffect, useState, useRef } from "react";
import { Row, Col, message, Spin, Tabs } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { no_agenda } from "../../../assets/images/images";
import options from "../../../lib/data/options.json";
import {
  AgendaModal,
  ThemeButton,
  ThemeDropdown,
  KeyNoteCard,
  NetworkingSessionCard,
  SponsorslotCard,
  BreakCard,
  SponsorsPresentationCard,
  PanelDiscutionCard,
} from "../../../components/components";
import { useLazyGetUsersQuery } from "../../../services/auth";
import { getUserInfo, showDeletePopup } from "../../../helpers/helpers";
import {
  useAddScheduleMutation,
  usePutScheduleMutation,
  useDeleteScheduleMutation,
  useLazyGetSchedulesQuery,
} from "../../../services/schedule";
import {
  useAddMeetingsMutation,
  usePutMultipleMeetingsMutation,
  useDeleteMultipleMeetingsMutation,
} from "../../../services/meetings";
import { useAddAgendaMutation } from "../../../services/agenda";
import { savePDF, PDFExport } from "@progress/kendo-react-pdf";
import { useLazyGetAttendeesQuery } from "../../../services/attendees";
import {
  useCreateSlotMutation,
  useDeleteSlotAllMutation,
  useDeleteSlotMutation,
  useUpdateSlotManyMutation,
} from "../../../services/slots";
import {
  useLazyGetEventByIDQuery,
  useLazyGetEventsQuery,
} from "../../../services/events";
import { useGetMeetingsQuery } from "../../../services/newMeeting";

const EventAgenda = ({
  eventID,
  isAdmin,
  isAddAgenda,
  getUserAgenda,
  eventName,
  currentPage,
  resultPerPage,
  type,
  dontShowMeetings,
}) => {
  const pdfExportComponent = useRef(null);
  const [getSchedules, { isLoading }] = useLazyGetSchedulesQuery();
  const [getUsers, { isLoading: fetchUsers }] = useLazyGetAttendeesQuery();
  const [addSchedule, { isLoading: addingSchedule }] = useAddScheduleMutation();
  const [updateSchedule, { isLoading: updating }] = usePutScheduleMutation();
  const [updateSlot, { isLoading: updatingSlots }] =
    useUpdateSlotManyMutation();
  const [createSlot, { isLoading: createSlots }] = useCreateSlotMutation();
  const [deleteSlotAll, { isLoading: deletingSlots }] =
    useDeleteSlotAllMutation();
  const [deleteSchedule, { isLoading: deleting }] = useDeleteScheduleMutation();
  const [addMeetings, { isLoading: addingMeetings }] = useAddMeetingsMutation();
  const [updateMeetings, { isLoading: updatingMeetings }] =
    usePutMultipleMeetingsMutation();
  const [deleteMultipleMeetings, { isLoading: deletingMeetings }] =
    useDeleteMultipleMeetingsMutation();
  const [addUserAgenda, { isLoading: addingAgendas }] = useAddAgendaMutation();
  const [getEventByID, { isLoading: loadingEvent }] =
    useLazyGetEventByIDQuery();

  // console.log(, "");

  const [mode, setMode] = useState("create");
  const [loading, setLoading] = useState(false);
  const [editID, setEditID] = useState("");
  const [agendas, setAgendas] = useState([]);
  const [agendaModal, setAgendaModal] = useState(false);
  const [speakers, setSpeakers] = useState([]);
  const [day, setDay] = useState("Day 1");
  const [formData, setFormData] = useState({
    title: "",
    short_title: "",
    description: [],
    start_time: "",
    end_time: "",
    event: eventID,
    hall: null,
    day: null,
    type: null,
    speaker: null,
    image: "",
  });
  const [meetings, setMeetings] = useState([]);
  const [deletedMeetings, setDeletedMeetings] = useState([]);
  const [event, setEvent] = useState([]);
  const [hidePDFData, setHidePDFData] = useState(false);
  const [deleteSlot, { isLoading: deleteSlots }] = useDeleteSlotMutation();

  useEffect(() => {
    getAgendas();
  }, [day, currentPage]);

  useEffect(() => {
    getSpeakers();

    const getEvent = async () => {
      const response = await getEventByID(eventID);

      if (response?.data.events.data[0]) {
        setEvent(response?.data.events.data[0]);
      }

      console.log(response, "responseresponse");
    };

    getEvent();
  }, []);

  // const getAgendas = async () => {
  //   let hallA = [];
  //   let hallB = [];
  //   const response = await getSchedules({
  //     currentPage: currentPage || 1,
  //     resultPerPage: resultPerPage || 1000,
  //     eventID: eventID,
  //     day,
  //     type: type || "",
  //   });
  //   const data = response?.data?.schedules?.data || [];
  //   for (var i = 0; i < data.length; i++) {
  //     if (data[i].hall === "Hall A") {
  //       hallA.push(data[i]);
  //     } else if (data[i].hall === "Hall B") {
  //       hallB.push(data[i]);
  //     } else {
  //       hallA.push(data[i]);
  //       hallB.push(data[i]);
  //     }
  //   }
  //   setAgendas([
  //     hallA.sort(
  //       (a, b) =>
  //         new Date("1970/01/01 " + a.start_time) -
  //         new Date("1970/01/01 " + b.start_time)
  //     ),
  //     hallB.sort(
  //       (a, b) =>
  //         new Date("1970/01/01 " + a.start_time) -
  //         new Date("1970/01/01 " + b.start_time)
  //     ),
  //   ]);
  // };

  const getAgendas = async () => {
    let hallA = [];
    let hallB = [];
    const response = await getSchedules({
      currentPage: currentPage || 1,
      resultPerPage: resultPerPage || 1000,
      eventID: eventID,
      day,
      type: type || "",
    });
    const data = response?.data?.schedules?.data || [];

    for (var i = 0; i < data.length; i++) {
      if (data[i].hall === "Hall A") {
        hallA.push(data[i]);
      } else if (data[i].hall === "Hall B") {
        hallB.push(data[i]);
      } else {
        hallA.push(data[i]);
        hallB.push(data[i]);
      }
    }

    // Add missing types to the opposite hall except for specific types
    const specificTypes = [
      "coffee-break",
      "lunch-break",
      "cocktail-break",
      "breakfast",
      "registration",
    ];

    data.forEach((item) => {
      if (!specificTypes.includes(item.type)) {
        if (
          item.hall === "Hall A" &&
          !hallB.some((i) => i.type === item.type)
        ) {
          hallB.push({ ...item, hall: "Hall B" });
        } else if (
          item.hall === "Hall B" &&
          !hallA.some((i) => i.type === item.type)
        ) {
          hallA.push({ ...item, hall: "Hall A" });
        }
      }
    });

    // Sort agendas by start time
    hallA.sort(
      (a, b) =>
        new Date("1970/01/01 " + a.start_time) -
        new Date("1970/01/01 " + b.start_time)
    );

    hallB.sort(
      (a, b) =>
        new Date("1970/01/01 " + a.start_time) -
        new Date("1970/01/01 " + b.start_time)
    );

    hallA.forEach((halAData, ind) => {
      // console.log('Hall A Start Time-->', halAData.start_time)
      // console.log('Hall B Start Time-->', hallB[ind]?.start_time)
      if (hallB[ind] && halAData.start_time !== hallB[ind]?.start_time) {
        hallB.splice(ind, 0, {
          type: "empty",
          start_time: halAData.start_time,
          end_time: halAData.end_time,
          title: "Need to add Data",
        });
      }
    });

    console.log("hallA-->", hallA);
    console.log("hallB-->", hallB);

    setAgendas([hallA, hallB]);
  };

  const getSpeakers = async () => {
    let speakersData = [];
    const response = await getUsers({
      eventID: eventID,
    });
    response?.data?.attendees?.data?.map((speaker) => {
      speakersData.push({
        value: speaker?.user?._id,
        label: `${speaker?.user?.first_name} ${speaker?.user?.last_name}`,
      });
    });

    setSpeakers(speakersData);
  };

  const onClose = () => {
    setMode("create");
    setAgendaModal(false);
    setFormData({
      title: "",
      short_title: null,
      description: "",
      start_time: null,
      end_time: null,
      event: eventID,
      hall: null,
      day: null,
      type: null,
      speaker: null,
      image: "",
    });
    setMeetings([]);
    setDeletedMeetings([]);
  };

  const onSubmit = async () => {
    setLoading(true);

    console.log(meetings, "meetings");

    console.log(meetings, "meetings");

    async function updateSlotss() {
      // Map the meetings data
      let mapSlots = meetings?.map((item) => ({
        _id: item._id,
        startTime: item.startTime,
        endTime: item.endTime,
        meetingType: item.meetingType,
        event: formData.event,
        day: formData.day,
      }));

      let data = {
        ...formData,
        slots: mapSlots,
      };

      if (data?.slots?.length > 0) {
        const mapupadteData = data?.slots?.map((item) => ({
          _id: item._id,
          updateData: {
            ...item,
          },
        }));

        console.log(mapupadteData, "mapupadteDatat");
        const response = await updateSlot({
          slots: mapupadteData,
        });

        if (deletedMeetings?.length > 0) {
          for (const item of deletedMeetings) {
            const responseDelete = await deleteSlot(item?._id);
            console.log(responseDelete, "responseDelete");
          }

          setDeletedMeetings([]);
        }

        if (response?.data?.status === 200) {
          message.success("Slot Updated successfully.");

          onClose();
          getAgendas();
          setMeetings([]);
          data = [];
          mapSlots = [];
        } else {
          message.error(response.error.data.message || "Slot not Updated .");
        }
      }
    }

    try {
      if (mode === "create") {
        const mapSlots = meetings?.map((item) => ({
          _id: item._id,
          startTime: item.startTime,
          endTime: item.endTime,
          meetingType: item.meetingType,
          event: formData.event,
          day: formData.day,
        }));

        const data = {
          ...formData,
          slots: mapSlots,
        };
        const response = await addSchedule(data);

        console.log(response, "response");
        if (response?.data?.status === 200) {
          message.success("Agenda created successfully.");
          setFormData((prev) => ({ ...prev, start_time: "", end_time: "" }));

          onClose();
          getAgendas();
        } else {
          message.error(response.error.data.message || "Agenda not added.");
        }
      } else {
        const itemsWithoutId = meetings?.filter((item) => !item?._id);

        // if update
        const mapSlotsWithoutId = itemsWithoutId?.map((item) => ({
          startTime: item.startTime,
          endTime: item.endTime,
          meetingType: item.meetingType,
          event: formData.event,
          day: formData.day,
        }));

        if (itemsWithoutId?.length > 0) {
          let createdIDs = [];
          for (const item of mapSlotsWithoutId) {
            try {
              const response = await createSlot({
                slots: item, // Pass the individual item to createSlot
              });

              if (response?.data?.status === 200) {
                createdIDs.push(response?.data?.slotultRes);
              }
              console.log("Saved item:", response);
            } catch (error) {
              console.error("Error saving item:", error);
            }
          }

          updateSlotss();

          console.log(createdIDs, "createdIDs");
          // Map the meetings data
          const mapSlots = meetings?.map((item) => ({
            _id: item._id,
            startTime: item.startTime,
            endTime: item.endTime,
            meetingType: item.meetingType,
            event: formData.event,
            day: formData.day,
          }));

          const data = {
            ...formData,
            slots: mapSlots,
          };

          let map = [...mapSlots];

          for (const item of createdIDs) {
            map.push(item);
          }

          const filter = map?.filter((item) => item._id);

          const dataAfterAddedNewIDs = {
            ...formData,
            slots: filter,
          };

          console.log(dataAfterAddedNewIDs, "dataAfterAddedNewIDs");

          const response = await updateSchedule({
            id: editID,
            body: dataAfterAddedNewIDs,
          });
          if (response?.data?.status === 200) {
            message.success("Agenda Updated successfully.");
            onClose();
            getAgendas();
            createdIDs = [];
            map = [];
          } else {
            message.error(
              response.error.data.message || "Agenda not Updated ."
            );
          }
        } else {
          updateSlotss();
          // Map the meetings data
          const mapSlots = meetings?.map((item) => ({
            _id: item._id,
            startTime: item.startTime,
            endTime: item.endTime,
            meetingType: item.meetingType,
            event: formData.event,
            day: formData.day,
          }));

          const data = {
            ...formData,
            slots: mapSlots,
          };

          const response = await updateSchedule({ id: editID, body: data });
          if (response?.data?.status === 200) {
            message.success("Agenda Updated successfully.");
            onClose();
            getAgendas();
          } else {
            message.error(
              response.error.data.message || "Agenda not Updated ."
            );
          }
        }
      }
    } catch (error) {
      // Handle errors during API calls
      message.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      // Ensure loading state is reset regardless of success or error
      setLoading(false);
    }
  };

  const addAgenda = async (data) => {
    try {
      const agenda = {
        event: data.event,
        user: getUserInfo().id,
        schedule: data._id,
      };
      const response = await addUserAgenda(agenda);
      if (response?.data?.status === 200) {
        message.success("Agenda added successfully.");
        onClose();
      } else {
        message.error(response?.error?.data?.message || "Something went wrong");
      }
      getUserAgenda();
    } catch (err) {
      console.log(err);
    }
  };

  const onEdit = (data) => {
    let panellist = [];
    data?.panellist?.forEach((user) => {
      panellist.push(user?._id);
    });

    console.log(data?._id, "data?._id");
    setEditID(data?._id);
    setFormData({
      title: data?.title,
      short_title: data?.short_title,
      description: data?.description || [],
      start_time: data?.start_time,
      end_time: data?.end_time,
      event: data?.event,
      hall: data?.hall,
      day: data?.day,
      type: data?.type,
      image: data?.image,
      speaker: data?.speaker?._id,
      moderator: data?.moderator?._id,
      panellist: panellist,
    });
    setMeetings(data?.slots || []);
    setMode("edit");
    setAgendaModal(true);
  };

  const onDelete = async (data) => {
    if (data?.type === "networking") {
      const mapp = data?.slots?.map((item) => ({
        _id: item._id,
      }));

      const meetingResponse = await deleteSlotAll({ ids: mapp });
      if (meetingResponse?.data?.status === 200) {
        message.success("Slots Deleted Successfully.");
        getAgendas();
      } else {
        message.error("Something went wrong");
      }
    }
    const response = await deleteSchedule(data?._id);
    if (response?.data?.status === 200) {
      message.success("Agenda Deleted Successfully.");
      getAgendas();
    } else {
      message.error("Something went wrong");
    }
  };

  const handleExportWithComponent = (event) => {
    setHidePDFData(true);
    pdfExportComponent.current.save();
    setTimeout(() => {
      setHidePDFData(false);
    }, 1000);
  };

  // useEffect(() => {
  //   console.log('agendas==>', agendas[0])
  // }, [agendas])

  const [slotsData, setSlotsData] = useState({
    eventId: eventID,
  });

  const {
    data,
    refetch,
    isLoading: slotFetchingLoading,
  } = useGetMeetingsQuery(slotsData);

  console.log(data, "data");

  const filterSponsorsAll = data?.meetings?.filter(
    (item) =>
      item.isAdditional === false && item.meetingType === "sponsor-to-all"
  );
  const filterDelegateAll = data?.meetings?.filter(
    (item) =>
      item.isAdditional === false && item.meetingType === "delegate-to-all"
  );

  //This is used for Days Tab
  const items = options.days.map((option) => ({
    key: option.key,
    label: option.label,
    value: option.value
  }));



  return (
    <div>
      <AgendaModal
        isModalOpen={agendaModal}
        onClose={onClose}
        onSubmit={onSubmit}
        formData={formData}
        setFormData={(e) => setFormData(e)}
        meetings={meetings}
        setMeetings={(e) => setMeetings(e)}
        deletedMeetings={deletedMeetings}
        setDeletedMeetings={setDeletedMeetings}
        isLoading={loading}
        eventID={eventID}
        speakers={speakers}
        headings={options.agendaHeadings}
        days={options.days}
        halls={options.halls}
        activityToBeDoneList={options.activityToBeDoneList}
        mode={mode}
        event={event}
      />
      <div className="flex items-center justify-between">
        {/* {isAdmin && ( */}
        <Tabs
          defaultActiveKey={day}
          items={items}
          onChange={(e) => setDay(e)}
        />
        {/* )} */}
        <div className="flex-1 py-1 px-4 mr-2 ml-2 text-center">
          <h1 className="text-[#42572A] text-[22px] font-bold">{day}</h1>
        </div>
        <div className="flex">
          <ThemeButton
            className="hidden"
            content={"Download"}
            onClick={handleExportWithComponent}
          />
          {isAdmin && (
            <ThemeButton
              content={"Add Agenda"}
              className={"ml-3"}
              onClick={() => setAgendaModal(true)}
            />
          )}
        </div>
      </div>
      <div>
        Test
      </div>
      <div className="rounded-xl mt-6">
        {isLoading ? (
          <div
            className="flex justify-center items-center"
            style={{ height: "75vh" }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <>
            {agendas.length ? (
              <PDFExport ref={pdfExportComponent} paperSize="auto">
                <div>
                  <div className="flex justify-between bg-primary py-4 px-10 mt-4">
                    {!hidePDFData && event?.event_hall !== "single-hall" && (
                      <p className="text-white font-extrabold text-xl md:text-5xl text-center">
                        HALL A
                      </p>
                    )}
                    <p className="text-white font-extrabold text-xl md:text-5xl text-center">
                      {day}
                    </p>
                    {event?.event_hall !== "single-hall" && (
                      <p className="text-white font-extrabold text-xl md:text-5xl text-center">
                        HALL B
                      </p>
                    )}
                  </div>
                  <div className="flex mt-6">
                    <div className="flex-1 mt-7 lg:mt-0 mb-5 mr-4">
                      {agendas[0]?.map((v, i) => {
                        if (v.type == "keynote") {
                          return (
                            <div className="flex">
                              <div
                                key={i}
                                className="flex-1 mt-7 lg:mt-0 mx-2 mb-5"
                              >
                                <KeyNoteCard
                                  isAdmin={isAdmin}
                                  keynote={v}
                                  onEdit={onEdit}
                                  onDelete={onDelete}
                                />
                                {isAddAgenda && (
                                  <ThemeButton
                                    onClick={() => addAgenda(v)}
                                    content={
                                      <div>
                                        <PlusCircleOutlined className="mr-4" />
                                        <span>Add to agenda</span>
                                      </div>
                                    }
                                    className="w-full text-lg !h-12  mt-4"
                                  />
                                )}
                              </div>

                              {agendas[1][i] &&
                                agendas[1][i].type === "keynote" &&
                                event?.event_hall !== "single-hall" ? (
                                <div
                                  key={i}
                                  className="flex-1 mt-7 mx-2 lg:mt-0 mb-5"
                                >
                                  <KeyNoteCard
                                    isAdmin={isAdmin}
                                    keynote={agendas[1][i]}
                                    onEdit={onEdit}
                                    onDelete={onDelete}
                                  />
                                  {isAddAgenda && (
                                    <ThemeButton
                                      onClick={() => addAgenda(v)}
                                      content={
                                        <div>
                                          <PlusCircleOutlined className="mr-4" />
                                          <span>Add to agenda</span>
                                        </div>
                                      }
                                      className="w-full text-lg !h-12 mt-4"
                                    />
                                  )}
                                </div>
                              ) : null}
                            </div>
                          );
                        } else if (v.type == "panel-discussion") {
                          return (
                            <div key={i} className="mt-7 lg:mt-0 mb-5">
                              <PanelDiscutionCard
                                isAdmin={isAdmin}
                                panelDetails={v}
                                onEdit={onEdit}
                                hall={"Hall A"}
                                onDelete={onDelete}
                              />
                              {isAddAgenda && (
                                <ThemeButton
                                  onClick={() => addAgenda(v)}
                                  content={
                                    <div>
                                      <PlusCircleOutlined className="mr-4" />
                                      <span>Add to agenda</span>
                                    </div>
                                  }
                                  className="w-full text-lg !h-12 mt-4"
                                />
                              )}
                            </div>
                          );
                        } else if (v.type == "sponsor-slot") {
                          return (
                            <div key={i} className="mt-7 lg:mt-0 mb-5">
                              <SponsorslotCard
                                isAdmin={isAdmin}
                                sponsorDetail={v}
                                onEdit={onEdit}
                                onDelete={onDelete}
                              />
                              {isAddAgenda && (
                                <ThemeButton
                                  onClick={() => addAgenda(v)}
                                  content={
                                    <div>
                                      <PlusCircleOutlined className="mr-4" />
                                      <span>Add to agenda</span>
                                    </div>
                                  }
                                  className="w-full text-lg !h-12 mt-4"
                                />
                              )}
                            </div>
                          );
                        } else if (v.type == "sponsor-presentation") {
                          return (
                            <div key={i} className="mt-7 lg:mt-0 mb-5">
                              <SponsorsPresentationCard
                                hall="Hall A"
                                isAdmin={isAdmin}
                                sponsorDetail={v}
                                onEdit={onEdit}
                                onDelete={onDelete}
                              />
                              {isAddAgenda && (
                                <ThemeButton
                                  onClick={() => addAgenda(v)}
                                  content={
                                    <div>
                                      <PlusCircleOutlined className="mr-4" />
                                      <span>Add to agenda</span>
                                    </div>
                                  }
                                  className="w-full text-lg !h-12 mt-4"
                                />
                              )}
                            </div>
                          );
                        } else if (
                          v.type == "coffe-break" ||
                          v.type == "lunch-break" ||
                          v.type == "cocktail-break" ||
                          v.type == "breakfast" ||
                          v.type == "registration"
                        ) {
                          return (
                            <div className="flex w-100">
                              <div
                                key={i}
                                className="flex-1 mt-7 lg:mt-0 mx-2 mb-5"
                              >
                                <BreakCard
                                  isAdmin={isAdmin}
                                  breakDetail={v}
                                  onEdit={onEdit}
                                  onDelete={onDelete}
                                />
                              </div>

                              {(agendas[1][i] &&
                                agendas[1][i].type === "coffe-break") ||
                                agendas[1][i].type == "lunch-break" ||
                                agendas[1][i].type == "cocktail-break" ||
                                agendas[1][i].type == "breakfast" ||
                                agendas[1][i].type == "registration"
                                ? event?.event_hall !== "single-hall" && (
                                  <div
                                    key={i}
                                    className="flex-1 mt-7 lg:mt-0 mx-2 mb-5"
                                  >
                                    <BreakCard
                                      isAdmin={isAdmin}
                                      breakDetail={agendas[1][i]}
                                      onEdit={onEdit}
                                      onDelete={onDelete}
                                    />
                                  </div>
                                )
                                : null}
                            </div>
                          );
                        } else if (v.type == "networking") {
                          return (
                            <div key={i} className="mt-7 lg:mt-0 mb-5">
                              <NetworkingSessionCard
                                isAdmin={isAdmin}
                                networkingDetail={v}
                                onEdit={onEdit}
                                onDelete={onDelete}
                                filterCurrent={filterSponsorsAll}
                                isOtherNetworking={false}
                                dontShowMeetings={dontShowMeetings}
                              />
                            </div>
                          );
                        }
                        // else if (v.type == "other_networking") {
                        //   return (
                        //     <div key={i} className="mt-7 lg:mt-0 mb-5">
                        //       <NetworkingSessionCard
                        //         isAdmin={isAdmin}
                        //         networkingDetail={v}
                        //         onEdit={onEdit}
                        //         onDelete={onDelete}
                        //         filterCurrent={filterDelegateAll}
                        //         isOtherNetworking={true}
                        //       />
                        //     </div>
                        //   );
                        // }
                      })}
                    </div>
                  </div>
                </div>
              </PDFExport>
            ) : (
              <div
                className="bg-white rounded-lg mt-4 flex justify-center items-center flex-col"
                style={{ height: 450 }}
              >
                <img src={no_agenda} alt="" width={450} />
                <p className="font-bold text-4xl mt-3">
                  No <span className="text-primary">Event Agenda</span>
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EventAgenda;
